.footer{
  background: #FFFFFF;
  border-top: 1px solid #0000004D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding:0 30px 0 30px;
  margin-top: auto;
}

.heart-icon{
  height: 15px;
  width: 15px;
}

.need-help-text {
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .footer{
    flex-direction: column;
    padding: 25px 0 25px 0;
  }
}