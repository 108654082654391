:root {
  --primary: #632A77; 
  --primary-dark: #471958;
  --primary-disabled: #90779b;
  --primary-lighter-hover: #863991;
  --primary-lighter: #632A7766; 
  --primary-light: #632A7733; 
  --text: #233C38;
  --text-placeholder: #899694;
  --white: #FFFFFF;
  --error: #B60000;
  --warning: #FF8303;
  --background: #FAFAFA;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 8px;
  margin-left: 10px;
}
  
::-webkit-scrollbar-track {
  border: none;
  margin: 20px;
}
  
::-webkit-scrollbar-thumb {
  background: var(--primary-lighter);  
  border-radius: 60px;
  background-clip: padding-box;
}

/* Containers */
.body-container {
  justify-content: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  max-width: 520px;
}

.content-container-standalone { 
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-container {
  text-align: center;
  flex-direction: column;
  display: flex;
  padding-bottom: 20px;
}

.text-container-standalone {
  text-align: center;
  flex-direction: column;
  display: flex;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.verification-text {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.modal-close-button-wrapper {
  display: flex;
  justify-content: end;
}


.verification-modal-wrapper {
  max-width: 400px;
  width: calc(100% - 20px);
}

/* Text */

.text-community-label{
  font-weight: 700;
  font-size: 30px;
  line-height: 48px;
  color: var(--text)
}

.text-big{
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: var(--text)
}

.text-waiver-title{
  font-weight: 700;
  font-size: 30px;
  line-height: 48px;
  color: var(--text)
}

.text-medium{
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--text)
}

.text-medium-bold{
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--text)
}

.text-small{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text)
}

.text-small-bold{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--text)
}

.text-small-orange-underlined {
  color: #dab864;
  text-decoration: underline;
}

.text-link{
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.button-modified {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  text-align: left
}


@media only screen and (max-width: 600px) {
  .text-big{
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: var(--text)
  }
}

.main-container {
  background-color:var(--background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  font-size: 16px;
  font-weight: 700;
}

a:link {
  color: var(--primary);
}

a:visited {
  color: var(--primary);
}

.phone-input-bold{
  margin: 10px 0 10px 0 !important;
  font-size: 20px !important;
  width: 100% !important;
  max-width: 520px !important;
  padding-left: 70px !important;
  height: 64px !important;
  border: 2px solid var(--primary-lighter) !important;
  outline: 0px solid var(--primary-light) !important;
  color: var(--text) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  transition: .1s;
}

.phone-input-regular{
  margin: 10px 0 10px 0 !important;
  width: 100% !important;
  max-width: 520px !important;
  padding-left: 70px !important;
  height: 64px !important;
  border: 2px solid var(--primary-lighter) !important;
  outline: 0px solid var(--primary-light) !important;
  color: var(--text) !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  transition: .1s;
  margin: 0 10px;
}

.phone-input-bold:focus{
  border: 2px solid var(--primary-lighter) !important;
  outline: 3px solid var(--primary-light) !important;
}

.phone-input-regular:focus{
  border: 2px solid var(--primary-lighter) !important;
  outline: 3px solid var(--primary-light) !important;
}

.phone-input-regular::placeholder{
  color: var(--text);
}

.phone-button{
  border: none !important;
  background: none !important;
}

.phone-button:hover{
  border: none !important;
  background: none !important;
}

/* 
.react-tel-input{
  margin: 10px 0 10px 0 !important;
  
}

.react-tel-input .form-control{
  width: 100% !important;
  max-width: 520px !important;
  padding-left: 70px !important;
  height: 64px !important;
  border: 2px solid var(--primary-lighter) !important;
  color: var(--text) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.react-tel-input .form-control{
  width: 100% !important;
  max-width: 520px !important;
  padding-left: 70px !important;
  height: 64px !important;
  border: 2px solid var(--primary-lighter) !important;
  color: var(--text) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.react-tel-input .flag-dropdown{
  border: none !important;
  background: none !important;
}
*/

.react-tel-input{
  margin: 10px 0 !important;
}

.react-tel-input .selected-flag{
  margin-right: 10px !important;
  margin-left: 10px !important;
  border: none !important;
  background: none !important;
}
/*
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
  border: none !important;
  background: none !important;
} 
*/
.react-tel-input .selected-flag .arrow{
  left: 30px !important;
} 

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}